import React, { useState } from 'react';
import Details from '../dish/Details';
import PreferredButton from '../UI/PreferredButton';
import formats from '../../utils/formats';
import { useRestaurant } from '../../utils/contexts/restaurant-context';
import { useParams } from 'react-router';
import { useTracker } from '../../utils/contexts/tracker-context';
import CtaNoOrders from './CtaNoOrders';
import clsx from "clsx";
import assets from '../../utils/assets';

export default function Dish(props) {
    const { dish, isTastingMenu } = props;

    const { type } = useParams();
    const { trackDataFn } = useTracker();
    const { restaurant } = useRestaurant();

    const [showDishModal, setShowDishModal] = React.useState(false);

    async function onDetailsOpenHandler() {
        await trackDataFn(restaurant.id, restaurant.token, "dishview", {
            "type": type,
            "dish": dish.id,
            "name": dish.name,
            "price": dish.price
        }, restaurant.has_orders);

        // try {
        //     await window.fbq("track", "ViewContent");
        // } catch (err) {
        //     console.log({ pixelError: err });
        // } finally {
        //     setShowDishModal(true);
        // }

        setShowDishModal(true);
    };

    return (
        <div className="dish p-2">
            {dish.special ?
                <div className="w-1/2 px-3 rounded-t-lg text-sm text-center bg-yellow-500">PIATTO CONSIGLIATO</div> :
                null}

            <div className={clsx("flex rounded-lg shadow bg-grey-100", { "rounded-b-none": dish.suggested_drink })}>
                <div className="flex-1 py-2 px-4">
                    <div className="h-full flex flex-col justify-between">
                        <div>
                            <p className="flex items-center font-semibold">{formats.capitalize(dish.name)}</p>

                            <Details dish={dish} showDishModal={showDishModal} setShowDishModal={setShowDishModal} onDetailsOpenHandler={onDetailsOpenHandler} />
                        </div>

                        {formats.shouldDisplayFilterIcon(dish) ?
                            <div className="flex items-center gap-2">
                                <img className="inline-block w-6 mr-2" src={assets.assetsPath(dish.icon)} alt="" />
                                <span className="uppercase text-sm">{formats.chooseIconText(dish.icon)}</span>
                            </div> : null}
                    </div>
                </div>

                <div className="flex flex-col items-end py-2 pr-4 text-base">
                    <PreferredButton item={dish} beverage={false} />

                    {!isTastingMenu && formats.cleanPrice(dish.price) !== "" ?
                        <p className="mt-2 font-bold">{formats.formatPrice(dish.price, restaurant.id)}</p> : null}

                    {(typeof dish.image_path === "string" && dish.image_path !== "") ?
                        <img src={dish.image_path} className="object-cover w-26 h-26 my-3 rounded-lg border border-grey-300" onClick={onDetailsOpenHandler} alt="dish" /> :
                        null}
                </div>
            </div>

            {(dish.suggested_drink !== "") ?
                <div className="suggested-drink py-1 px-3 text-sm rounded-b-lg bg-grey-400">
                    <p><span className="font-bold">Ottimo con:</span> <span className="capitalize">{dish.suggested_drink.name}</span></p>
                </div> : null}

            {(Number(dish.variations.length) !== 0 || Number(dish.additions.length) !== 0) ? <CtaNoOrders element={dish} /> : null}
        </div>
    );
}
